import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="salary-calculation"
export default class extends Controller {
  static targets = [ 'preview', 'workingHours', 'salary', 'variableAmountPercent', 'variableAmountType' ]

  declare readonly salaryTarget: HTMLInputElement
  declare readonly workingHoursTarget: HTMLInputElement
  declare readonly variableAmountPercentTarget: HTMLInputElement
  declare readonly variableAmountTypeTargets: HTMLInputElement[]
  declare readonly previewTarget: HTMLElement

  connect() {
    this.calculate();
  }

  calculate() {
    let salary: number = parseFloat(this.salaryTarget.value);
    let workingHours: number = parseFloat(this.workingHoursTarget.value);
    let value: number = 0;
    if (salary > 0 && workingHours > 0) {
      value = salary * 40 / workingHours;
    } else {
      return 0;
    }
    let variableAmountType = this.variableAmountTypeTargets.find((el: HTMLInputElement) => el.checked)?.value || 'Ohne';
    let variableAmountPercent: number = parseFloat(this.variableAmountPercentTarget.value);
    if (variableAmountType === 'Vom Grundgehalt' || variableAmountType === 'grundgehalt') {
      value = value * (1 + (variableAmountPercent / 100));
    } else if (variableAmountType === 'Vom Zielgehalt' || variableAmountType === 'zielgehalt') {
      value = value / (1 - (variableAmountPercent / 100));
    }
    if (Number.isNaN(value)) { value = 0 } else { value };
    this.previewTarget.textContent = this.format(value);
  }

  format = (value: number)  => {
    return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
  }
}
