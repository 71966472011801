// import 'select2/dist/js/i18n/de';
import "@pludoni/frontend-pack/dist/vue/axios.js"

// fontawesome
import '@fortawesome/fontawesome-free/js/all.min';

import '@/utils/rails';
import $ from "@/utils/jquery";
import select2 from 'select2/dist/js/select2.full';
select2($);
import '@/utils/conditionalToggle';
import '@/utils/loader';

import "bootstrap/dist/js/bootstrap.bundle.min";
import './application.scss';

import AutomountAll from '@pludoni/frontend-pack/src/vue/automount';
import FloatingAkos from "@/application/components/FloatingAkos.vue"

AutomountAll({
  'floating-akos': {
    component: FloatingAkos
  }
});

import '@/controllers'

$(document).ready(function() {
  $('#organisation_addresses_imported_location_id').select2({
    placeholder: "Wählen Sie Standorte",
    width: '100%'
  });

  const el = document.querySelector('#handsontable')
  if (el)  {
    import("@/utils/handsontable").then(mount => {
      mount.default(el)
    })
  }
  $('[data-toggle="tooltip"]').tooltip()
});


