$(function() {
  const loader = {
    show: function(e) {
      $('body').addClass('app-loading');
      setTimeout(function() {
        loader.hide();
      }, 5000);
    },
    hide: (e) => { $('body').removeClass('app-loading'); }
  };
  $(document).on('page:before-change', loader.show);
  $(document).on('page:load page:restore', loader.hide);
  $(document).on('modal:loading', loader.show);
  $(document).on('modal:changed', loader.hide);
});
