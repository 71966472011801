import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conditional-toggle"
// <div data-controller="conditional-toggle"
//   data-conditional-toggle-condition-boolean-value="true" 
//   data-conditional-toggle-toggle-class-value="d-none">
//
//  <input type="checkbox" data-action="conditional-toggle#toggle" data-conditional-toggle-target="source">
//
//  <div class='d-none data-conditional-toggle-target="target">
//    <p>Some content</p>
interface ConditionObject {
  toggleOn: String | Boolean | String[],
  inputValue?: String,
}

export default class extends Controller {
  static targets = [ "source", "target" ]

  declare readonly targetTarget: HTMLElement


  static values = { 
    toggleClass: { type: String, default: 'd-none' },
    condition: Object,
  }

  declare readonly conditionValue: ConditionObject
  // declare readonly conditionBooleanValue: boolean
  // declare readonly conditionStringValue: string
  // declare readonly conditionNumberValue: number
  // declare readonly conditionArrayValue: Array<string>
  declare readonly toggleClassValue: string


  connect() {
  }

  toggle(event: Event) {
    let conditionMet : boolean = false;
    let source: HTMLInputElement = event.target as HTMLInputElement;
    let inputType = source?.type;

    if (inputType === 'checkbox') {
      conditionMet = source?.value === this.conditionValue.inputValue && 
        source?.checked === this.conditionValue.toggleOn;
    }
    if (inputType === 'radio') {
      if (this.conditionValue.toggleOn instanceof Array) {
        conditionMet = this.conditionValue.toggleOn.includes(source?.value);
      } else {
        conditionMet = source?.value === this.conditionValue.toggleOn;
      }
    }

    if (conditionMet) {
      this.targetTarget.classList.remove(this.toggleClassValue);
    } else {
      this.targetTarget.classList.add(this.toggleClassValue);
    }
  }
}
