window.setConditionalToggle = function(radio, toggleValue, toggleElement) {
  return radio.change(function() {
    var found, i, len, shouldValues, val, vals;
    if (radio.is('select')) {
      vals = radio.find('option:selected').map(function() {
        return $(this).val();
      }).toArray();
    } else {
      vals = radio.filter(':checked').map(function() {
        return $(this).val();
      }).toArray();
    }
    shouldValues = [];
    if (typeof toggleValue !== 'object') {
      shouldValues = [toggleValue];
    } else {
      shouldValues = toggleValue;
    }
    found = false;
    for (i = 0, len = shouldValues.length; i < len; i++) {
      val = shouldValues[i];
      if (vals.indexOf(val.toString()) !== -1) {
        found = true;
      }
    }
    if (found) {
      toggleElement.removeClass('collapse');
    } else {
      toggleElement.addClass('collapse');
    }
    if (window.sr) {
      sr.sync();
    }
    return null;
  }).change();
};

